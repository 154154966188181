import { configureStore } from '@reduxjs/toolkit';
import { addressSlice } from './feature/addressSlice';
import { authSlice } from './feature/authSlice';
import { cartSlice } from './feature/cartSlice';
import { categorySlice } from './feature/categorySlice';
import { configsSlice } from './feature/configsSlice';
import { homeSlice } from './feature/homeSlice';
import { homePageSlice } from './feature/Pages/Home/homePageSlice';
import { layerSlice } from './feature/layerSlice';
import { listingSlice } from './feature/listingSlice';
import { orderSlice } from './feature/orderSlice';
import { payoutSlice } from './feature/payout';
import { searchSlice } from './feature/search';
import { storeSlice } from './feature/storeSlice';
import { store_orderSlice } from './feature/store_orderSlice';
import { transactionsSlice } from './feature/transactionsSlice';
import { wishSlice } from './feature/wishSlice';
import { digitalContentSlice } from './feature/digital content/digitalContentSlice';
import { collectionsSlice } from './feature/Collections/CollectionsSlice';
import { bannersSlice } from './feature/Home/bannerSlice';
import { layersSlice } from './feature/Layers/layerSlice';
import { notificationsSlice } from './feature/notificationSlice';
import { commissionsSlice } from './feature/Commissions/CommissionsSlice';
import { CardSlice } from './feature/card/cardSlice';
import { translateContentSlice } from './feature/Translate/translateSlice';
import { walletSlice } from './feature/wallet/walletSlice';
import { kycSlice } from './feature/KYC/kycSlice';
import { tagListingsPageSlice } from './feature/Pages/TagListings/TagListingSlice';
import { negotiateSlice } from './feature/negotiate/negotiateSlice';
import { countriesSlice } from './feature/countrySlice';
import { listingsCataloguePageSlice } from './feature/Pages/ListingsCatalogue/ListingsCatalogue';
import { OPPSlice } from './feature/OPP/OPPSlice';
import { sidebarSlice } from './feature/SidebarSlice';
import { dynamicPageSlice } from './feature/Pages/DynamicPage/dynamicPageSlice';
import unauthorizedMiddleware from '../middlewares/unauthorizedMiddleware';
import { SubscriptionSlice } from './feature/subscription/subscriptionSlice';
import { filterSlice } from './feature/filter/filterSlice';
import { accountFilterSlice } from './feature/filter/accountFilterSlice';
import { taxesSlice } from './feature/tax/taxSlice';

const store = configureStore({
  reducer: {
    home: homeSlice.reducer,
    homePage: homePageSlice.reducer,
    auth: authSlice.reducer,
    listing: listingSlice.reducer,
    cart: cartSlice.reducer,
    order: orderSlice.reducer,
    category: categorySlice.reducer,
    store: storeSlice.reducer,
    store_order: store_orderSlice.reducer,
    payout: payoutSlice.reducer,
    configs: configsSlice.reducer,
    search: searchSlice.reducer,
    wish: wishSlice.reducer,
    transactions: transactionsSlice.reducer,
    address: addressSlice.reducer,
    layer: layerSlice.reducer,
    digital_content: digitalContentSlice.reducer,
    collections: collectionsSlice.reducer,
    notifications: notificationsSlice.reducer,
    layers: layersSlice.reducer,
    banners: bannersSlice.reducer,
    commissions: commissionsSlice.reducer,
    wallet: walletSlice.reducer,
    card: CardSlice.reducer,
    translate_content: translateContentSlice.reducer,
    kyc: kycSlice.reducer,
    tagListingsPage: tagListingsPageSlice.reducer,
    listingsCataloguePage: listingsCataloguePageSlice.reducer,
    negotiate: negotiateSlice.reducer,
    countries: countriesSlice.reducer,
    opp: OPPSlice.reducer,
    sidebar: sidebarSlice.reducer,
    dynamicPage: dynamicPageSlice.reducer,
    subscription: SubscriptionSlice.reducer,
    filter: filterSlice.reducer,
    account_filter: accountFilterSlice.reducer,
    tax: taxesSlice.reducer,
  },

  devTools: process.env.ENVIRONMENT !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
